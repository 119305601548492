let complex = require('../static/complex.png') // 综合体
let governmentProcurement = require('../static/governmentProcurement.png') // 政采
let institution = require('../static/institution.png') // 机构

let _this = null
let BMap = null
let map = null

function bDmap(BMaps, ele, regionData) {
  // 赋值构造地图实例方法
  BMap = BMaps
  // 创建Map实例
  map = new BMap.Map('publicMap')
  // 绘制行政区划轮廓
  let region = regionData
  drawPolygon(region, ele)
  // 启用滚轮放大缩小
  map.enableScrollWheelZoom(true)
}

// 绘制行政区划轮廓
function drawPolygon(regionName, ele) {
  let bdary = new BMap.Boundary()
  bdary.get(regionName, function (rs) {
    //轮廓的总数有的区由多个封闭区域组成
    let count = rs.boundaries.length
    if (!count) {
      console.log('未能获取到当前输入的行政区域')
      return
    }
    // 循环绘制
    for (let i = 0; i < count; i++) {
      //创建多边形覆盖物
      let ply = new BMap.Polygon(rs.boundaries[i], {
        strokeWeight: 3,
        strokeColor: '#368EFF',
        strokeStyle: 'solid',
        fillOpacity: 0.15,
        fillColor: '#368EFF',
      })
      if (i == 0) {
        //调整视野
        map.setViewport(ply.getPath())
      }
      //添加覆盖物
      map.addOverlay(ply)
    }
    // 百度地图自定义样式
    map.setMapStyleV2({
      styleId: 'f3cd572fe93000d35eb96c8b04dc0c3a',
    })
    // 循环地图打点
    cycleManagement(ele)
  })
}

// 循环地图打点
function cycleManagement(ele) {
  ele.forEach((item) => {
    punctuationPopup(item)
  })
}

// 地图标记点
function punctuationPopup(item) {
  var point = new BMap.Point(item.lng, item.lat)
  // 创建点标记
  let imgIcon =
    item.storeType == 1 || item.storeType == 2
      ? institution
      : item.storeType == 0
      ? complex
      : governmentProcurement
  var myIcon = new BMap.Icon(imgIcon, new BMap.Size(42, 36))
  myIcon.setImageSize(new BMap.Size(42, 36))
  var marker = new BMap.Marker(point, { icon: myIcon })
  map.addOverlay(marker)
  // 创建信息窗口
  var opts = {
    offset: new BMap.Size(-35, 40), // 设置文本偏移量
  }
  var infoWindow = new BMap.InfoWindow(textBox(item), opts)
  // 点标记添加移入事件
  marker.addEventListener('mouseover', function () {
    map.openInfoWindow(infoWindow, point) // 开启信息窗口
  })
  // 点标记添加移出事件
  marker.addEventListener('mouseout', function (e) {
    map.closeInfoWindow(infoWindow, point) // 开启信息窗口
  })
  //设置文本标记
  var opts2 = {
    position: point, // 指定文本标注所在的地理位置
    offset: new BMap.Size(0, 18), // 设置文本偏移量
  }
  let content = `
    <div>
        <div class='labelSty' style="transform: translateX(-50%);position: absolute;left: 50%;color: #FFFFFF;font-size: 12px;">
        ${item.name}
        </div>
    </div>
  `
  // 创建文本标注对象
  var label = new BMap.Label(content, opts2)
  map.addOverlay(label)
}

// 弹框内容
function textBox(data) {
  let textData = `
        <div class='mapPop'>
            <div class='mapPop-name'>${data.name}</div> 
            <div class='mapPop-unit'>
                <div class='mapPop-unit-label'>运营单位：</div>
                <div class='mapPop-unit-value'>${data.spName}</div>
            </div>
            <div class='mapPop-area'>
                <div class='mapPop-area-label'>经营面积：</div>
                <div class='mapPop-area-value'>${data.area}</div>
            </div>
            <div class='occupancy-rate'>
                <div class='occupancy-rate-label'>入住率：</div>
                <div class='occupancy-rate-value'>${data.percent}</div>
            </div>
        </div>
    `
  return textData
}

export default {
  thisFun: (vm) => {
    _this = vm
  },
  bDmap,
  cycleManagement,
}
