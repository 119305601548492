<template>
  <!-- 二级大屏地图 -->
  <div class="publicMapMain">
    <div id="publicMap" class="publicMap"></div>
    <div class="lowerRight">
      <div class="lowerRight-item" v-if="storeType == 1 || storeType == 2">
        <div><img src="./static/institution.png" /></div>
        <div>养老机构</div>
      </div>
      <div class="lowerRight-item" v-if="storeType == 0">
        <div><img src="./static/complex.png" /></div>
        <div>养老服务综合体</div>
      </div>
      <div class="lowerRight-item" v-if="storeType == 3">
        <div><img src="./static/governmentProcurement.png" /></div>
        <div>政府采购项目</div>
      </div>
    </div>
  </div>
</template>
<script>
import { loadBaiDuMap } from '@/utils/baiduUitls'
import bdMapConfig from './config/bdMap_config'
export default {
  props: {
    // 区域中文地点
    region: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      storeType: null, // 类型：机构 storeType = 1，2；综合体 storeType = 0;政府采购 storeType = 3
    }
  },
  created() {
    bdMapConfig.thisFun(this)
  },
  methods: {
    bDmap: bdMapConfig.bDmap,
    initializeMap(listData) {
      this.storeType = listData[0].storeType
      loadBaiDuMap({ edition: '3.0' }).then((BMap) => {
        this.bDmap(BMap, listData, this.region)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import './style/index.scss';
</style>
